const translation = {
  en: {
    WELCOME_TO: "Welcome to",
    GET_TICKET_AT: "Get a ticket at",
    TICKET_SENT: "We have sent the ticket to your inbox",
    JOIN_BLURB:
      "Join queues at an ever-expanding number of %s partners and say goodbye to waiting",
    GET_IT: "Get it for your phone, or laptop at",
    CHECK_INBOX: "Check your inbox",
    NUMBER_ISSUED: "You've been issued ticket number",
    ALL_DONE: "All Done",
    ACCESS_VIA_APP_AT: "You can access the ticket via the app available at",
    PAGE_REFRESHES_IN: "Page refreshes in %s seconds",
    THANKS_FOR_USING: "Thanks for using %s",
    PICK_CLIENT: "Find the Client",
    PICK_LOCATION: "Pick a Location",
    PICK_DAY: "Pick a Month, then the Day",
    PICK_TIME: "Pick a Time of Day",
    PICK_ONE: "Pick one or more reasons",
    KNOW_MORE: "Want to know more?",
    STOP_QUEUING: "Stop queuing, start using",
    SLOGAN: "...because waiting sucks.",
    NEXT: "Next",
    SCAN_QR_CODE: "Scan the QR Code for quick access",
  },
  af: {
    WELCOME_TO: "Welkom by %s",
    GET_TICKET_AT: "Kry u kaartjie",
    TICKET_SENT: "Ons het die kaartjie na jou posbus gestuur",
    JOIN_BLURB:
      "Neem deel aa toue by 'n toenemende aantal vennote van %s en neem afskeid van wag",
    GET_IT: "Kry dit vir jou selfoon of skootrekenaar by %s",
    CHECK_INBOX: "Kyk na jou posbus",
    NUMBER_ISSUED: "Kaartjie nommer 12345 is aan jou uitgereik",
    ALL_DONE: "Alles klaar",
    ACCESS_VIA_APP_AT:
      "U kan toegang vir die kaartjie kry via die app besikikbaar by %s",
    PAGE_REFRESHES_IN: "Webbladsye verfris binne %s sekondes",
    THANKS_FOR_USING: "Dankie vir die gebruik %s",
    PICK_LOCATION: "Kies 'n lokasie",
    PICK_DAY: "Kies 'n dag",
    PICK_TIME: "Kies 'n tyd van die dag",
    PICK_ONE: "Kies een of meer",
    KNOW_MORE: "Wil u meer weet?",
    STOP_QUEUING: "Ophou tou, gebruik %s",
    SLOGAN: "...want wag suig",
    NEXT: "volgende",
    SCAN_QR_CODE: "Scan the QR Code for quick access",
  },
  fr: {
    WELCOME_TO: "Bienvenu a %s",
    GET_TICKET_AT: "Obtenir ton billet a",
    TICKET_SENT:
      "Nous vous avons envoye le billet dans votre boite de reception",
    JOIN_BLURB:
      "Join queues at an ever-expanding number of %s's partners and say goodbye to waiting",
    GET_IT: "Recevez-le pour votre telephone ou laptop a %s",
    CHECK_INBOX: "Verifiez votre boite de reception",
    NUMBER_ISSUED: "Vous avez ete attribuer le ticket numero %s",
    ALL_DONE: "Bon",
    ACCESS_VIA_APP_AT:
      "Vous pouvez acceder a votre ticket sur notre application mobile at %s",
    PAGE_REFRESHES_IN: "La page sera raffraichi dans %s seconds",
    THANKS_FOR_USING: "Merci d'avoir utiliser %s",
    PICK_LOCATION: "Choisissez votre endroit",
    PICK_DAY: "Choisissez un jour",
    PICK_TIME: "Choisissez votre temps",
    PICK_ONE: "Choisissez un ou plusieurs",
    KNOW_MORE: "Voulez-vous en savoir plus?",
    STOP_QUEUING: "Arretez de faire la queue et commencer a utiliser %s",
    SLOGAN: "...parceque c'est ennuyant de faire la queue",
    NEXT: "Prochain",
    SCAN_QR_CODE: "Scan the QR Code for quick access",
  },
};
///italian/french/spanish/dutch/portuguese
export default translation;
