import Swal from "sweetalert2";
import { apiUrl } from "../constants";
import { get, post } from "../helpers";

export const checkIfPfnExists = async (
  pfn,
  onNegative,
  setRegisteredNames = () => null
) => {
  get(`${apiUrl}/chiawelo/pfn-exists/${pfn}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .then((status) => {
      if (!status.exists) {
        onNegative(status.exists);
      } else {
        console.log({ status });
        setRegisteredNames(status.names || []);
      }
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const updateNumber = async (oldNumber, number) => {
  if (!number || number.length < 10) return;
  let cleanNumber = number.replace(/[\s-]/g, "");
  if (cleanNumber.match(/^0/)) cleanNumber = "+27" + cleanNumber.substring(1);
  return get(`${apiUrl}/chiawelo/update_number/${oldNumber}/${cleanNumber}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      Swal.showValidationMessage(`Request failed: ${error}`);
    });
};

export const updateProfile = async (data) => {
  return post(`${apiUrl}/chiawelo/update-name-number`, data)
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      Swal.showValidationMessage(`Request failed: ${error}`);
    });
};

export const showSetPhonePopUp = (
  customerId,
  oldNumber,
  compoundPfn,
  onSuccess = () => null,
  onError = () => null
) => {
  const match = compoundPfn.match(/^(?<pfn>[^()]+)\s+\((?<name>[^()]+)\)/);
  let currentOwnerName;
  if (match) {
    currentOwnerName = match.groups.name.trim().toLowerCase();
  }

  if (currentOwnerName === "-" || !currentOwnerName) {
    Swal.fire({
      title: `Enter correct mobile number`,
      input: "text",
      position: "top",
      inputAttributes: {
        autocapitalize: "off",
      },
      inputValidator: (input) => {
        if (!/^[0-9]{10}$/.test(input)) {
          return "Invalid number, please use this format 0761234567";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Save Phone Number",
      showLoaderOnConfirm: true,
      preConfirm: (numberInput) => {
        Swal.fire({
          title: "What is the Client's Name?",
          input: "text",
          position: "top",
          inputAttributes: {
            autocapitalize: "on",
          },
          inputValidator: (input) => {
            if (!/^[a-z-]{3,}$/i.test(input)) {
              return "Please use a valid name";
            }
          },
          showCancelButton: true,
          confirmButtonText: "Confirm Name",

          showLoaderOnConfirm: true,
          preConfirm: (nameInput) => {
            const newName = nameInput
              .toLowerCase()
              .trim()
              .replace(/[^a-z-]/gi, "");
            if (newName) {
              return updateProfile({
                name: newName,
                mobile: numberInput,
                customerId,
              });
            } else {
              alert("Please enter a valid name.");
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          if (result.value.success) {
            onSuccess(result.value);
          } else {
            onError(result.value);
          }
        } else {
          onError({
            message:
              "Not sure if the data was updated, please refresh the page",
          });
        }
      }
    });
  } else {
    Swal.fire({
      title: `Enter correct mobile number`,
      input: "text",
      position: "top",
      inputAttributes: {
        autocapitalize: "off",
      },
      inputValidator: (input) => {
        if (!/^[0-9]{10}$/.test(input)) {
          return "Invalid number, please use this format 0761234567";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Save Phone Number",
      showLoaderOnConfirm: true,
      preConfirm: (numberInput) => {
        return updateProfile({ mobile: numberInput, customerId });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value) {
          if (result.value.success) {
            onSuccess(result.value);
          } else {
            onError(result.value);
          }
        } else {
          onError({
            message:
              "Not sure if the data was updated, please refresh the page",
          });
        }
      }
    });
  }
};

export const showCheckinPopUp = (
  customerId,
  ticket_id,
  onSuccess = () => null,
  onError = () => null
) => {
  Swal.fire({
    title: "Check in this client?",
    position: "top",
    showCancelButton: true,
    confirmButtonText: "Check in",
    showLoaderOnConfirm: true,
    preConfirm: () => {
      return post(`${apiUrl}/chiawelo/checkin/${customerId}`, { ticket_id })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.json();
        })
        .catch((error) => {
          Swal.showValidationMessage(`Request failed: ${error}`);
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (result.isConfirmed) {
      const { value: data } = result;
      if (data) {
        if (data.success) {
          onSuccess();
          Swal.fire({
            title: `Checkin Successful!`,
            text: data.message,
            icon: "success",
            position: "top",
          });
        } else {
          Swal.fire({
            title: `Checkin was not completed`,
            text: data.message,
            icon: "warning",
            position: "top",
          });
        }
      }
    }
  });
};
