// eslint-disable no-unreachable
import React from "react";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./index.css";
import Ui from "./components/Ui";
import Login from "./components/Login";
import rootReducer from "./redux/reducers/rootReducer";

const store = createStore(rootReducer, applyMiddleware(thunk));

const Protected = (props) => {
  const token = localStorage.getItem("_chiawelo_token");

  if (!token) {
    return <Redirect path="/" />;
  }

  return <Route {...props}></Route>;
};

const Sidebar = () => {
  return (
    <Provider store={store}>
      <div className={"kiosk uk-height-viewport"}>
        <BrowserRouter>
          <Switch>
            <Route exact path={"/"} component={Login} />
            <Protected exact path={"/desk"}>
              <Ui />
            </Protected>
          </Switch>
        </BrowserRouter>
        <div
          style={{ left: "" }}
          className={
            "uk-text-right tiny-text uk-padding-small uk-position-bottom uk-position-fixed"
          }
        >
          Powered by{" "}
          <a href={"https://joinaq.com"}>
            <img
              width={60}
              src={"https://joinaq.com/assets/logo.svg"}
              alt={"joinaQ&trade; Personal Queuing Assistant"}
            />
          </a>
        </div>
      </div>
    </Provider>
  );
};

export default Sidebar;
