import React, { Fragment } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import TimeBiasList from "../TimeBiasList";
import DayList from "../DayList";
import ServicePointList from "../ServicePointList";
import translator from "../../translation/translator";
import { apiUrl } from "../../constants";
import AddPersonToPFN from "../AddPersonToPFNForm";
import NumberCorrectionInput from "../NumberCorrectionInput";
import { detectErrors, post } from "../../helpers";

const translation = translator();

const BookingTab = ({
  branches,
  setGettingTicket,
  setSearchPfn,
  branchId,
  selectedClient,
  request,
  searchByPFN,
  searching,
  searched,
  pfnSuggestions,
  searchPfnRef,
  clearPFNSearch,
  setRegisterPopUpVisible,
  clearSelectedClient,
  customer,
  lookupBookings,
  setPfnName,
  setSelectedClient,
  getJoinaQCustomerByReference,
  someoneNewFormVisible,
  setSomeoneNewFormVisible,
  isMobileValid,
  updateNumber,
  setIsMobileValid,
  bookings,
  gettingTicket,
  pfnName,
  clearTransaction,
  getTickets,
}) => {
  const getTicket = (pfn, customerId, payload, onSuccess = () => null) => {
    if (!payload) {
      return;
    }

    payload.customer_id = customerId;
    payload.preferred_number = `${pfn} (${pfnName.toUpperCase()})`;
    payload.issuance_method = "concierge";

    const errors = detectErrors(payload);
    if (errors.length < 1) {
      setGettingTicket(true);
      post(`${apiUrl}/chiawelo/enqueue`, payload)
        .then((res) => res.json())
        .then(({ success, code, ticket, message }) => {
          setGettingTicket(false);
          if (success) {
            onSuccess(ticket);
            Swal.fire({
              icon: "success",
              title: "Booked Successfully!",
              position: "top",
              html: `Booked for <strong>${moment(
                ticket.estimated_call_time
              ).format(
                "dddd [the] Do [of] MMMM [at] h:mmA"
              )}</strong><br />Please inform the client`,
              showCloseButton: true,
            });
          } else {
            if (code === "CONGESTION") {
              Swal.fire({
                icon: "info",
                title: "Full",
                position: "top",
                html: `Please try a different time of day (or different date), the selected timeslot is full`,
                showCloseButton: true,
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: message || code || "Unexpected Error",
                position: "top",
                html: code,
                showCloseButton: true,
              });
            }
          }
        });
    } else {
      setGettingTicket(false);
      Swal.fire({
        icon: "warning",
        title: "Problem",
        position: "top",
        html: errors.join("<br />"),
        showCloseButton: true,
      });
    }
  };
  return (
    <Fragment key={"booking-form-tab-view"}>
      {(!branches || branches.length < 1) && (
        <div className={"uk-padding-large uk-text-center"}>
          Loading settings...
        </div>
      )}
      {branches && Array.isArray(branches) && branches.length > 0 && (
        <Fragment key={"booking-form-tab-view-form"}>
          <dl className="steps">
            {
              <Fragment key={"booking-form-tab-view-form-steps"}>
                {!!!selectedClient && (
                  <Fragment key={"booking-form-tab-view-form-steps-lists"}>
                    <dt>{translation.PICK_CLIENT}</dt>
                    <dd className={!request.facility_id ? "uk-hidden" : ""}>
                      <form autoComplete="off"
                        onSubmit={(evt) => {
                          evt.preventDefault();
                          searchByPFN();
                        }}
                      >
                        {searching && (
                          <div style={{ color: "#fff" }}>
                            Searching...please wait.
                          </div>
                        )}
                        {((searched && pfnSuggestions.length < 1) ||
                          !searched) && (
                          <div style={{ position: "relative" }}>
                            {!searched && !searching && (
                              <input autoComplete="new-password"
                                type={"text"}
                                className="uk-input"
                                ref={searchPfnRef}
                                placeholder={
                                  "Enter Patient File Number... and hit <<ENTER>> ↵"
                                }
                                onChange={(evt) =>
                                  setSearchPfn(evt.target.value)
                                }
                              />
                            )}

                            {!searching && pfnSuggestions.length > 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  right: 0,
                                  top: 0,
                                  bottom: 0,
                                  lineHeight: "40px",
                                  padding: "0 10px 0 10px",
                                  borderTopRightRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  backgroundColor: "#000",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                onClick={clearPFNSearch}
                              >
                                Clear
                              </div>
                            )}
                          </div>
                        )}
                        <div
                          className={
                            pfnSuggestions.length > 0
                              ? "uk-margin-top suggestions days-container closed"
                              : ""
                          }
                        >
                          {pfnSuggestions.length > 0 && (
                            <ul className="uk-list day-list">
                              {(pfnSuggestions || []).map((suggestion) => (
                                <li
                                  key={suggestion.pfn}
                                  onClick={() => {
                                    clearPFNSearch();
                                    setSelectedClient(suggestion);
                                  }}
                                >
                                  {suggestion.pfn} (
                                  {!!suggestion && (
                                    <em>
                                      {
                                        (suggestion.names || "").split(",")
                                          .length
                                      }{" "}
                                      patients)
                                    </em>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                          {searched && pfnSuggestions.length === 0 && (
                            <div style={{ color: "#fff" }}>
                              No matching PFN was found. Try different spellings
                              or REGISTER NEW PFN.
                            </div>
                          )}
                          {searched && (
                            <>
                              <span
                                className={"uk-button uk-button-danger"}
                                onClick={() => setRegisterPopUpVisible(true)}
                              >
                                Register New PFN
                              </span>{" "}
                              <span
                                className={"uk-button uk-button-secondary"}
                                onClick={() => clearPFNSearch()}
                              >
                                Search Again
                              </span>
                            </>
                          )}
                        </div>
                      </form>
                    </dd>
                  </Fragment>
                )}
                {!!selectedClient && (
                  <div>
                    <span className={"white-text"}>
                      {selectedClient.pfn} -{" "}
                    </span>
                    <button
                      className={"uk-button uk-button-small uk-button-warning"}
                      href={"about:blank"}
                      onClick={(evt) => {
                        evt.preventDefault();
                        clearSelectedClient();
                      }}
                    >
                      Change PFN
                    </button>
                  </div>
                )}
              </Fragment>
            }
            {!!customer &&
              !!selectedClient &&
              lookupBookings &&
              lookupBookings.length > 0 && (
                <div className={"uk-padding uk-warning upcoming"}>
                  <h3>Past/Present Bookings for this PFN</h3>
                  <ul>
                    {lookupBookings.map((ticket) => (
                      <li key={ticket.estimated_call_time}>
                        <strong>{ticket.alias || ticket.number}</strong> -{" "}
                        {moment(ticket.estimated_call_time).format("llll")}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            {!!selectedClient && (
              <Fragment key={"booking-form-tab-view-form-steps-details"}>
                <dt>Who is being Booked?</dt>
                <dd className={!request.facility_id ? "uk-hidden" : ""}>
                  <div className={"days-container uk-padding closed"}>
                    {selectedClient.names && (
                      <ul className={"uk-list day-list white"}>
                        {(selectedClient || "").names.split(",").map((name) => (
                          <li key={name}>
                            <label>
                              <input autoComplete="new-password"
                                name="name"
                                className="uk-radio"
                                type="radio"
                                onChange={(evt) => {
                                  if (evt.target.checked) {
                                    const nameParts = name.split(":");
                                    setPfnName(nameParts[1]);
                                    setSelectedClient({
                                      ...selectedClient,
                                      name: nameParts[1],
                                      reference: nameParts[0],
                                    });

                                    getJoinaQCustomerByReference(nameParts[0]);
                                  } else {
                                    setPfnName("");
                                    setSelectedClient({
                                      ...selectedClient,
                                      name: "",
                                      reference: "",
                                    });
                                  }
                                }}
                              />{" "}
                              {(name.split(":")[1] || "").replace("-", " ")}
                            </label>
                          </li>
                        ))}
                      </ul>
                    )}
                    {/*<input
                                className="uk-input"
                                placeholder={
                                  "Booking someone new? Enter their first name..."
                                }
                                onChange={(evt) => {
                                  const cleaned = evt.target.value
                                    .trim()
                                    .replace(/\s.+/, "");
                                  setPFName(cleaned);
                                  evt.target.value = evt.target.value
                                    .trim()
                                    .replace(/\s.+/, "");
                                }}
                              />*/}
                    {!someoneNewFormVisible && (
                      <div className={"uk-margin"}>
                        <span
                          className="uk-button uk-button-danger"
                          onClick={() => {
                            setSomeoneNewFormVisible(true);
                          }}
                        >
                          Add Someone New
                        </span>
                      </div>
                    )}
                    {someoneNewFormVisible && (
                      <div className={"uk-margin"}>
                        <hr />
                        <h4>Add New Patient</h4>
                        <AddPersonToPFN
                          setSomeoneNewFormVisible={setSomeoneNewFormVisible}
                          otherPeopleAtAddress={(
                            (selectedClient || "").names || ""
                          )
                            .split(",")
                            .map((elm) => {
                              const parts = elm.split(":");
                              return parts[1];
                            })}
                          onRegistration={(reference, name) => {
                            setPfnName(name);
                            setSelectedClient({
                              ...selectedClient,
                              names: `${selectedClient.names},${reference}:${name}`
                            });
                          }}
                          pfn={selectedClient.pfn}
                          onClose={() => {
                            setSomeoneNewFormVisible(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </dd>
                {customer && (
                  <>
                    {!isMobileValid && (
                      <NumberCorrectionInput
                        oldNumber={customer.mobile}
                        updateNumber={updateNumber}
                        setIsMobileValid={(isValid) =>
                          setIsMobileValid(isValid)
                        }
                      />
                    )}
                    <dt>{translation.PICK_DAY}</dt>
                    <dd className={!request.facility_id ? "uk-hidden" : ""}>
                      <DayList bookings={bookings || []} />
                    </dd>
                    <dt>{translation.PICK_TIME}</dt>
                    <dd className={!request.preferred_date ? "uk-hidden" : ""}>
                      <TimeBiasList showNextAvailable={!!branchId} />
                    </dd>
                    <dt>{translation.PICK_ONE}</dt>
                    <dd className={!request.bias ? "uk-hidden" : ""}>
                      <ServicePointList bookings={bookings} />
                    </dd>
                  </>
                )}
              </Fragment>
            )}
          </dl>
          {!!customer && !!selectedClient && (
            <Fragment key={"booking-form-tab-view-form-button"}>
              <hr />
              <button
                className={"uk-button uk-button-danger"}
                disabled={
                  !selectedClient ||
                  gettingTicket ||
                  !request.preferred_date ||
                  !request.bias ||
                  !request.required_services ||
                  request.required_services.filter(
                    (serviceId) => serviceId !== "606cc9536047c60015cdc440"
                  ).length < 1 ||
                  !isMobileValid ||
                  !pfnName
                }
                onClick={() =>
                  getTicket(selectedClient.pfn, customer._id, request, () => {
                    clearTransaction();
                    getTickets();
                  })
                }
              >
                {gettingTicket ? "BOOKING..." : `BOOK ${selectedClient.pfn}`}
              </button>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default BookingTab;
