import React, { useEffect } from "react";
import AddPFNForm from "../AddPFNForm";

const RegistrationPopUp = ({
  registerPopUpVisible,
  newPfn,
  disableRegistration,
  setPfnValue,
  updateNewPfn,
  setRegisterPopUpVisible,
  registerPFN,
  clearPFNSearch,
  setDisableRegistration,
  registering,
  cancelling,
  registeredNames,
}) => {
  useEffect(() => {
    updateNewPfn({ action: "reset" });
    setDisableRegistration(true);
  }, []);

  return (
    <div
      id="modal-example"
      className="uk-modal uk-open"
      style={{
        display: "block",
      }}
    >
      <div className="uk-modal-dialog uk-modal-body">
        <h2 className="uk-modal-title">
          Register {newPfn.pfn ? "PFN as " + newPfn.pfn : "New PFN"}
        </h2>
        {disableRegistration && newPfn.pfn && (
          <div className={"uk-alert uk-alert-danger"}>
            {newPfn.pfn} is already registered
            {registeredNames && registeredNames.length > 0 && (
              <p>
                <strong>Registered with:</strong> {registeredNames.join(", ")}
              </p>
            )}
          </div>
        )}
        {registerPopUpVisible && (
          <AddPFNForm
            setPFNValue={(value, lat, lon) => setPfnValue(value, lat, lon)}
            setValue={(action, value) => updateNewPfn({ action, value })}
          />
        )}
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
            onClick={() => {
              clearPFNSearch();
              setRegisterPopUpVisible(false);
              setDisableRegistration(true);
              updateNewPfn({ action: "reset" });
            }}
          >
            Cancel
          </button>{" "}
          <button
            onClick={() =>
              registerPFN(() => {
                clearPFNSearch();
                setRegisterPopUpVisible(false);
                setDisableRegistration(true);
                updateNewPfn({ action: "reset" });
              })
            }
            disabled={disableRegistration || registering || cancelling}
            className="uk-button uk-button-danger"
            type="button"
          >
            {registering ? "Registering..." : "Register"}
          </button>
        </p>
      </div>
    </div>
  );
};

export default RegistrationPopUp;
