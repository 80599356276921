import { getUserLanguage } from "../helpers";
import translation from "./index";

const translator = (language = null) => {
  if (language) {
    return translation[language in translation ? language : "en"];
  }

  let userLanguage = getUserLanguage();
  userLanguage = userLanguage in translation ? userLanguage : "en";
  return translation[userLanguage];
};

export default translator;
