import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import {clock, calculateActiveHours, getSecondsSinceMidnight} from "../helpers";

class TimeBiasList extends Component {
  times = {
    dawn: { starts: 0, ends: 4 },
    daybreak: { starts: 4, ends: 8 },
    morning: { starts: 8, ends: 10 },
    brunch: { starts: 10, ends: 12 },
    lunch: { starts: 12, ends: 14 },
    afternoon: { starts: 14, ends: 17 },
    evening: { starts: 17, ends: 19 },
    night: { starts: 19, ends: 23 },
  };

  constructor(props) {
    super(props);
    this.showNextAvailable = props.showNextAvailable;
  }

  filterTimes() {
    const slots = {};
    const { branch, request } = this.props;
    let hours;
    if (branch && branch.settings && request.preferred_date) {
      const dayName = moment(request.preferred_date, "DD-MM-YYYY").format(
        "dddd"
      );

      hours = branch.settings.operating_hours[dayName].opens ? calculateActiveHours(
          getSecondsSinceMidnight(branch.settings.operating_hours[dayName].opens),
          getSecondsSinceMidnight(branch.settings.operating_hours[dayName].closes)
      ): [];

      for (const key in this.times) {
        if (hours.includes(this.times[key].starts)) {
          slots[key] = this.times[key];
        }
      }
    }

    return slots;
  }

  render() {
    const times = this.filterTimes();
    const { request, setTimeBias } = this.props;
    let isTodaySelected = false;
    if (request.preferred_date === moment().format("DD-MM-YYYY")) {
      isTodaySelected = true;
    }

    // const bookingsByBias = {};
    // this.props.bookings.forEach((booking) => {
    //   const day = moment(booking.estimated_call_time).format("DD-MM-YYYY");
    //   bookingsByBias[day] = bookingsByBias[day] || {};
    //   booking.services.forEach((x) => {
    //     bookingsByBias[day][x.name] = bookingsByBias[day][x.name] || 0;
    //     bookingsByBias[day][x.name]++;
    //   });
    // });
    // console.log({ bookingsByBias });

    //todo: Add warning popup that choosing asap may result in immediate ticket call
    return (
      <div className={"uk-padding days-container closed"}>
        <ul className={"uk-list day-list"}>
          {this.showNextAvailable && isTodaySelected && (
            <li
              className={`${
                request.bias === "asap" ? "qm-selected " : ""
              }`}
              key={"asap"}
              onClick={() => setTimeBias("asap")}
            >
              {" "}
              <span className={"time-line"}>Next Available Ticket</span>{" "}
              <em> &nbsp; ASAP!</em>
            </li>
          )}
          {Object.keys(times).map((time) => {
            let isPast = false;

            /** If it's a day in the past, or a time from today but it is past relative to now */
            if (
              moment(request.preferred_date, "DD-MM-YYYY").endOf("day") <
                moment().startOf("day") ||
              (moment(request.preferred_date, "DD-MM-YYYY").isSame(
                moment(),
                "day"
              ) &&
                times[time].ends <
                  Math.abs(
                    moment(moment().startOf("day")).diff(moment(), "hours")
                  ))
            ) {
              isPast = true;
            }

            return (
              <Fragment key={"key-" + time}>
                {/** TODO: Hide time blocks that have passed for today - currently shows blocks for today that are earlier than now */}
                {(request.bias === time || !request.bias) && (
                  <li
                    className={`${
                      request.bias === time ? "qm-selected " : ""
                    }${isPast ? "not-selectable" : ""}`}
                    key={time}
                    onClick={() => setTimeBias(time)}
                  >
                    {" "}
                    <span className={"time-line"}>
                      {time}, ({clock(times[time].starts)} -{" "}
                      {clock(times[time].ends)})
                    </span>
                    {isPast && <em> &nbsp; Not available</em>}
                  </li>
                )}
              </Fragment>
            );
          })}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    request: state.request,
    branch: state.branch,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    setTimeBias: (bias) => dispatch({ type: "SET_TIMEBIAS", bias }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeBiasList);
