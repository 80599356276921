import React, { Component } from "react";
import { connect } from "react-redux";
import BrandLogo from "./BrandLogo";
import translator from "../translation/translator";

const translation = translator();

class Ticket extends Component {
  state = {
    clock: 60,
  };
  timer = null;
  componentDidMount() {
    this.timer = setInterval(() => {
      const clock = this.state.clock - 1;
      this.setState({ clock });
      if (clock === 0) window.location.reload();
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.time);
  }

  render() {
    return (
      <div className="">
        <BrandLogo />
        <div className="uk-padding uk-padding-remove-top uk-align-center uk-text-center ">
          {this.props.isNoob && (
            <div className={"uk-align-center"}>
              <div>{translation.WELCOME_TO}</div>
              <div className={"thanks-logo uk-align-center"}>
                <img
                  className={"uk-responsive-width "}
                  width={200}
                  src={"https://joinaq.com/assets/logo.svg"}
                  alt={"joinaQ™"}
                />
              </div>

              <p>
                {translation.TICKET_SENT}!
                <br />
                {translation.JOIN_BLURB.replace("%s", "joinaQ™")}.
                <br />
                {translation.GET_IT.replace("", "")}:
                <p>
                  <strong>
                    <a
                      href="https://joinaq.app"
                      rel="noopener noreferrer"
                      target={"_blank"}
                    >
                      joinaQ.app
                    </a>
                  </strong>
                </p>
                {translation.CHECK_INBOX}!
              </p>
            </div>
          )}
          {!this.props.isNoob && (
            <div className={"uk-align-center"}>
              {/*<div className={"thanks-logo uk-align-center"}>
                <img
                  className={"uk-responsive-width "}
                  src={"https://joinaq.com/assets/logo.svg"}
                  alt={"joinaQ™"}
                />
              </div>*/}

              <div>
                {translation.NUMBER_ISSUED.replace(
                  "%s",
                  this.props.ticket.number
                )}
                <div className={"uk-h1 big-number"}>
                  {this.props.ticket.number}
                </div>
                {translation.ACCESS_VIA_APP_AT}{" "}
                <a
                  href={"https://joinaq.app"}
                  rel="noopener noreferrer"
                  target={"_blank"}
                >
                  https://joinaq.app
                </a>
                .
                <br />
                {translation.SCAN_QR_CODE.replace("%s", "joinaQ™")}!
                <img
                  alt="joinaQ QR code"
                  src={"//joinaq.com/assets/app_qrcode-min.png"}
                />
              </div>
            </div>
          )}
          <p className={"uk-text-muted uk-text-small"}>
            {translation.PAGE_REFRESHES_IN.replace("%s", this.state.clock)}
          </p>
        </div>
      </div>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    ticketSucceeded: state.ticketSucceeded,
    isNoob: state.isNoob,
    ticket: state.ticket,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    clearSession: () => dispatch({ type: "CLEAR_SESSION" }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Ticket);
