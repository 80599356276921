import React, { useState } from "react";
import Swal from "sweetalert2";
import { put } from "../../helpers";
import { apiUrl } from "../../constants";

const transfer = (bookingId, serviceId, setTransferring, onSuccess) => {
  if (!bookingId || !serviceId) {
    return;
  }
  Swal.fire({
    title: "Do you really want to TRANSFER this client?",
    position: "top",
    showCancelButton: true,
    confirmButtonText: "Yes, Transfer!",
    showLoaderOnConfirm: true,
    preConfirm: () => {
      setTransferring(true);
      return put(`${apiUrl}/chiawelo/transfer`, {
        bookingId,
        serviceId,
      })
        .then((res) => res.json())
        .then(({ success, message, code }) => {
          setTransferring(false);
          if (success) {
            onSuccess();
            Swal.fire({
              icon: "success",
              title: "Transferred!",
              position: "top",
              html: `Transfer completed successfully!`,
              showCloseButton: true,
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "Not Transferred",
              position: "top",
              text: message || code,
              showCloseButton: true,
            });
          }
        })
        .catch((e) => {
          setTransferring(false);
          Swal.fire({
            icon: "error",
            title: "Not Transferred",
            position: "top",
            text: e.message,
            showCloseButton: true,
          });
        });
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};

const TransferPopUp = ({ booking, close, getTickets }) => {
  const [transferring, setTransferring] = useState(false);
  const [transferTo, setTransferTo] = useState(null);

  return (
    <div
      id="modal-example"
      className="uk-modal uk-open"
      style={{
        display: "block",
      }}
    >
      <div className="uk-modal-dialog uk-modal-body">
        <h2 className="uk-modal-title">Transfer {booking.number}</h2>
        <select
          className={"uk-select"}
          onChange={(e) => setTransferTo(e.target.value)}
        >
          <option value="">Choose where to transfer to...</option>
          {booking.services.map((service) => (
            <option key={service._id} value={service._id}>
              to {service.name.toUpperCase()}
            </option>
          ))}
        </select>
        <p className="uk-text-right">
          <button
            className="uk-button uk-button-default uk-modal-close"
            type="button"
            onClick={close}
          >
            Cancel
          </button>{" "}
          <button
            disabled={!transferTo}
            onClick={() =>
              transfer(booking._id, transferTo, setTransferring, () => {
                getTickets();
                close();
              })
            }
            className="uk-button uk-button-danger"
            type="button"
          >
            {transferring ? "Transferring..." : "Transfer"}
          </button>
        </p>
      </div>
    </div>
  );
};

export default TransferPopUp;
