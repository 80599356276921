import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import AddressAutoComplete from "./AddressAutoComplete";
import Swal from "sweetalert2";
import ReactMapGL, { Source, Layer, Marker } from "react-map-gl";
import Geocoder from "react-mapbox-gl-geocoder";
import { chiaweloCatchmentArea } from "../constants";

import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const mapboxApiKey =
  "pk.eyJ1IjoibW92ZXRoaXNzdHVmZiIsImEiOiJja3YxOXFkMGowZWJqMnZwN2d0M3QwNG1rIn0.BbKHb1UXGlQKVpDAcNf3iQ";

const params = {
  country: "za",
  bbox: [
    27.825445175700057, -26.294772273109963, 27.881492614233508,
    -26.257445754249456,
  ],
  proximity: { latitude: -26.275148486705213, longitude: 27.857545852593642 },
  limit: 5,
};

const mapStyle = {
  width: "100%",
  height: 300,
};

const AddPFNForm = ({ error, setValue, setPFNValue, mode = "add" }) => {
  const [noPhone] = useState(false);
  const [showSuggestionLink, setShowSuggestionLink] = useState(false);
  const [needHelp, setNeedHelp] = useState(false);
  const autoCompleteRefTime = useRef(null);

  const [mapState, setMapState] = useState({
    viewport: {
      latitude: -26.274868659668613,
      longitude: 27.867364599299993,
      zoom: 12.5,
    },
  });
  const [pfn_, setPfn_] = useState("");

  const captureInput = (evt) => {
    const { id, value } = evt.target;
    setValue(id, value);
  };
  //
  // const captureCheckboxInput = (evt) => {
  //   const { id, checked } = evt.target;
  //   setValue({ [id]: checked });
  // };

  const setPfnFallback = (viewport, item) => {
    if (item) {
      //{"id":"address.2588770077536500","type":"Feature","place_type":["address"],"relevance":0.97963,"properties":{"accuracy":"point"},"text":"Mannge Street","place_name":"12 Mannge Street, Soweto, Johannesburg, Gauteng 1811, South Africa","center":[27.86648,-26.278076],"geometry":{"type":"Point","coordinates":[27.86648,-26.278076]},"address":"12","context":[{"id":"neighborhood.7810419539923930","text":"Chiawelo"},{"id":"postcode.13080604646186960","text":"1811"},{"id":"locality.8546189021964170","wikidata":"Q217426","text":"Soweto"},{"id":"place.6183069998255330","wikidata":"Q34647","text":"Johannesburg"},{"id":"region.9937187847329030","wikidata":"Q133083","short_code":"ZA-GT","text":"Gauteng"},{"id":"country.13290890257841840","wikidata":"Q258","short_code":"za","text":"South Africa"}]}

      //construct mock googlemaps object
      const address = {
        street_number: "",
        route: item.place_name,
        position: {
          lon: item.geometry.coordinates[0],
          lat: item.geometry.coordinates[1],
        },
      };
      setPfn(item.place_name, address);
    }
  };

  const setPfn = (typedAddress, address) => {
    let pfn = "";
    if (!address.route) {
      Swal.fire({
        icon: "error",
        title: "Bad address",
        position: "top",
        text: "The address cannot be used as it has no street name/number. Use a Soweto address like 23 Lesebi Street.",
        showCloseButton: true,
      });
      return;
    }

    setMapState({
      viewport: {
        latitude: -26.274868659668613,
        longitude: 27.867364599299993,
        zoom: 12.5,
      },
      marker: {
        name: address.route,
        longitude: address.position.lon,
        latitude: address.position.lat,
      },
    });

    const route = (address.route || "")
      .replace(/^(Block|House\s|Door\s|Hse\s)?(No\.?|Number\.?)\s+/i, "")
      .trim()
      .replace(
        /\s+(Lane|Road|Street|Avenue|Way|Close|Drive|Crescent|Circle)(\s)*.*$/i,
        ""
      )
      .replace(/^No\.?\s/, "")
      .trim();

    if (address.street_number) {
      pfn = `${address.street_number} ${route}`;
    } else {
      const regex = new RegExp(route + " .+", "i");
      pfn = typedAddress.replace(regex, route);
    }

    pfn = pfn
      .split(" ")
      .map((part) => part.trim())
      .join(" ")
      .toUpperCase();
    setPfn_(pfn);
    setPFNValue({ pfn }, address.position.lat, address.position.lon);
  };

  return (
    <div className="">
      {error && error.message && (
        <div className={"uk-alert uk-alert-danger"}>{error.message}</div>
      )}
      <div>
        <fieldset className="uk-fieldset">
          <div className="uk-margin">
            {!needHelp && (
              <>
                <AddressAutoComplete
                  suggestionCount={(numResults) =>
                    setShowSuggestionLink(numResults < 1)
                  }
                  onBlur={(value) => {
                    clearTimeout(autoCompleteRefTime.current);
                    if (value && !pfn_) {
                      setShowSuggestionLink(true);
                    }
                  }}
                  onKeyUp={(value) => {
                    autoCompleteRefTime.current = setTimeout(() => {
                      if (value && !pfn_) {
                        setShowSuggestionLink(true);
                      }
                    }, 5000);
                  }}
                  onKeyDown={() => {
                    clearTimeout(autoCompleteRefTime.current);
                  }}
                  onPlaceSelect={(inputValue, place) => {
                    setPfn(inputValue, place);
                  }}
                />
                {showSuggestionLink && (
                  <>
                    Can't find a place?{" "}
                    <a href={"# "} onClick={() => setNeedHelp(true)}>
                      Click here
                    </a>
                    .
                  </>
                )}
              </>
            )}
            {needHelp && (
              <Geocoder
                mapboxApiAccessToken={mapboxApiKey}
                onSelected={(viewport, place) => {
                  setPfnFallback(viewport, place);
                }}
                viewport={mapState.viewport}
                hideOnSelect={true}
                value={""}
                initialInputValue={"Try your search again here."}
                queryParams={params}
              />
            )}
          </div>
          <div>
            {mapState.marker && (
              <ReactMapGL
                mapboxApiAccessToken={mapboxApiKey}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                {...mapState.viewport}
                {...mapStyle}
                onViewportChange={(viewport) =>
                  setMapState({ ...mapState, viewport })
                }
              >
                <Source
                  id="ciawelo"
                  type="geojson"
                  data={{
                    _id: "chiawelo",
                    type: "Feature",
                    geometry: {
                      coordinates: [chiaweloCatchmentArea],
                      type: "Polygon",
                    },
                  }}
                >
                  <Layer
                    id="blah"
                    type="fill"
                    source="ciawelo"
                    paint={{ "fill-color": "#cc0000", "fill-opacity": 0.5 }}
                  />
                </Source>
                {mapState.marker && (
                  <Marker
                    longitude={mapState.marker.longitude}
                    latitude={mapState.marker.latitude}
                  >
                    <div className="marker temporary-marker">
                      <span></span>
                    </div>
                  </Marker>
                )}
              </ReactMapGL>
            )}
          </div>
          {mode !== "edit" && (
            <div className="uk-margin">
              <input autoComplete="new-password"
                className="uk-input"
                type={"text"}
                placeholder={`First Name Only`}
                id="name"
                onKeyUp={(evt) =>
                  (evt.target.value = evt.target.value.toUpperCase())
                }
                onChange={captureInput}
              />
            </div>
          )}

          {/*<div className="uk-margin">
            <input autoComplete="new-password"
              className="uk-input"
              type={"email"}
              placeholder={`Email Address`}
              id="email"
              onKeyUp={(evt) =>
                (evt.target.value = evt.target.value.replace(/[\s]/g, ""))
              }
              onChange={captureInput}
            />
          </div>*/}

          {mode !== "edit" && (
            <div>
              <div className="uk-margin">
                {!noPhone && (
                  <input autoComplete="new-password"
                    className="uk-input"
                    type="tel"
                    placeholder="Mobile Number e.g 076123456"
                    id={"mobile"}
                    onKeyUp={(evt) =>
                      (evt.target.value = evt.target.value.replace(
                        /[^0-9]/g,
                        ""
                      ))
                    }
                    onChange={captureInput}
                  />
                )}
                {/*<label>
                    <input autoComplete="new-password"
                      type="checkbox"
                      id="no_phone"
                      onChange={(evt) => {
                        captureCheckboxInput(evt);
                        setNoPhone(evt.target.checked));
                      }}
                    />{" "}
                    Does not own a phone
                  </label>*/}
              </div>
            </div>
          )}
        </fieldset>
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    branches: state.branches,
    branch: state.branch,
    request: state.request,
    error: state.error,
    signUpEnabled: state.signUpEnabled,
    position: state.position,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    joinQ: () => dispatch({ type: "JOIN" }),
    toggleSignUp: () => dispatch({ type: "TOGGLE_SIGNUP" }),
    setError: (error) => dispatch({ type: "SET_ERROR", error }),
    ticketSucceeded: (ticket) => dispatch({ type: "SET_SUCCESS", ticket }),
    showFrame: () => dispatch({ type: "SHOW_FRAME" }),
    clearRequest: () => dispatch({ type: "CLEAR_REQUEST" }),
    setLastTicketType: (isNoob) =>
      dispatch({ type: "SET_TICKET_TYPE", isNoob }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddPFNForm);
