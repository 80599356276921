import React, { Component } from "react";

class AddressAutoComplete extends Component {
  inputId = React.createRef();
  constructor(props) {
    super(props);
    this.handleScriptLoad = this.handleScriptLoad.bind(this);
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
  }
  componentDidMount() {
    this.handleScriptLoad();
  }
  componentDidCatch(err, data) {
    console.log({ err, data });
  }
  composeAddress(place) {
    if (!(place && place.formatted_address)) return;
    //todo: utc_offset is deprecated as of November 2019 and will be turned off in November 2020. Use utc_offset_minutes instead.
    const utc_offset = Math.ceil(place.utc_offset_minutes / 60);
    const {
      formatted_address,
      vicinity,
      geometry: {
        location: { lat, lng: lon },
      },
    } = place;

    const addressData = {
      utc_offset,
      formatted_address,
      vicinity,
      country: {},
      position: {
        lon: lon(),
        lat: lat(),
        point: {
          type: "Point",
          coordinates: [lon(), lat()],
        },
      },
    };
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (addressType === "country") {
        addressData.country["name"] = place.address_components[i]["long_name"];
        addressData.country["tld"] = place.address_components[i]["short_name"];
      } else {
        addressData[addressType] = place.address_components[i]["long_name"];
      }
    }
    return addressData;
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    const parsed = this.composeAddress(addressObject);
    this.props.onPlaceSelect(this.inputId.current.value, parsed);
  }

  handleScriptLoad() {
    const options = {
      componentRestrictions: { country: "za" },
      types: ["geocode"],
    };
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputId.current,
      options
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  render() {
    return (
      <>
        <input
          className="uk-input"
          autoComplete={"new-password"}
          onPaste={(e) => e.preventDefault()}
          type="text"
          ref={this.inputId}
          onBlur={() => this.props.onBlur()}
          onKeyUp={(evt) => {
            const dropdownContainer =
              document.getElementsByClassName("pac-container");
            if (dropdownContainer) {
              const dropdown = dropdownContainer[0];
              this.props.suggestionCount(dropdown.children);
            }
            this.props.onKeyUp(evt.target.value);
          }}
          onKeyDown={(evt) => {
            this.props.onKeyDown(evt.target.value);
          }}
          placeholder="Type a Soweto Address e.g 123 Lesebi Street"
        />
      </>
    );
  }
}

AddressAutoComplete.displayName = "AddressAutoComplete";
export default AddressAutoComplete;
