import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../constants";
import { soundex } from "soundex-code";
import { metaphone } from "metaphone";
import { post } from "../helpers";

const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email,
  );

const AddPersonToPFN = ({
  pfn,
  onClose,
  onRegistration,
  otherPeopleAtAddress,
  setSomeoneNewFormVisible,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [noPhone] = useState(false);
  const [addingPerson, setAddingPerson] = useState(false);
  const nameRef = useRef("");

  const addPersonToPFN = async () => {
    if (!name || !mobile) {
      alert("Name and mobile number are required");
      return;
    }

    if (!/^0[678][0-9]{8}$/.test(mobile)) {
      alert(`The mobile number ${mobile} is invalid`);
      return;
    }

    if (email && !isValidEmail(email)) {
      //alert("The email address is invalid");
      //return;
    }

    setAddingPerson(true);
    post(`${apiUrl}/chiawelo/register`, { email, name, mobile, pfn, noPhone })
      .then((resp) => resp.json())
      .then((json) => {
        setAddingPerson(false);
        if (json) {
          if (json.person) {
            onRegistration(json.person.reference, json.person.name);
            onClose();
          } else {
            Swal.fire({
              icon: "error",
              text: json.message,
            });
          }
        }
      })
      .catch((e) => {
        alert(e.message);
        setAddingPerson(false);
      });
  };

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  return (
    <form autoComplete="off">
      <fieldset className="uk-fieldset">
        <div className="uk-margin">
          <input
            autoComplete="new-password"
            readOnly={addingPerson}
            className="uk-input"
            type={"text"}
            placeholder={`Enter first name and surname`}
            id="new_name"
            ref={nameRef}
            onKeyUp={(evt) =>
              (evt.target.value = evt.target.value
                .toUpperCase()
                .replace(/\s+/, "-"))
            }
            onChange={(evt) => setName(evt.target.value)}
            onBlur={(evt) => {
              if (evt.target.value.length > 0) {
                for (const nameOfOther of otherPeopleAtAddress) {
                  console.log(
                    otherPeopleAtAddress,
                    soundex(nameOfOther),
                    soundex(evt.target.value),
                  );
                  if (
                    soundex(nameOfOther) === soundex(evt.target.value) ||
                    metaphone(nameOfOther) === metaphone(evt.target.value) ||
                    nameOfOther.toLowerCase() === evt.target.value.toLowerCase()
                  ) {
                    Swal.fire({
                      title: "Wait!",
                      text: `${nameOfOther.toUpperCase()} sounds a lot like ${name.toUpperCase()}... are you sure this is not the same person?`,
                      position: "top",
                      showCancelButton: true,
                      confirmButtonText: "It's not the same person",
                      cancelButtonText: "Oops it is!",
                      allowOutsideClick: () => !Swal.isLoading(),
                    }).then((result) => {
                      if (!result.isConfirmed) {
                        nameRef.current.value = "";
                        setSomeoneNewFormVisible(false);
                      }
                    });
                  }
                }
              }
            }}
          />
        </div>

        <div className="uk-margin">
          <input
            autoComplete="new-password"
            readOnly={addingPerson}
            className="uk-input"
            type={"email"}
            placeholder={`Email Address`}
            id="new_email"
            onKeyUp={(evt) =>
              (evt.target.value = evt.target.value.replace(/[\s]/g, ""))
            }
            onChange={(evt) => setEmail(evt.target.value)}
          />
        </div>

        <div className="uk-margin">
          {!noPhone && (
            <input
              autoComplete="new-password"
              readOnly={addingPerson}
              className="uk-input"
              type="tel"
              placeholder="Mobile Number e.g 076123456"
              id={"new_mobile"}
              onKeyUp={(evt) =>
                (evt.target.value = evt.target.value.replace(/[^0-9]/g, ""))
              }
              onChange={(evt) => {
                setMobile(evt.target.value);
              }}
            />
          )}
          {/*!!name && (
            <label>
              <input autoComplete="new-password"
                type="checkbox"
                id="has_no_phone"
                onChange={(evt) => setNoPhone(evt.target.checked)}
              />{" "}
              {name} does not own a phone
            </label>
          )*/}
        </div>

        <div className="uk-margin">
          {!addingPerson && (
            <span className="uk-button uk-button-default" onClick={onClose}>
              Cancel Adding Someone New
            </span>
          )}
          <span className="uk-button uk-button-danger" onClick={addPersonToPFN}>
            {!addingPerson ? `Add to ${pfn}` : "Adding..."}
          </span>
        </div>
      </fieldset>
    </form>
  );
};

export default AddPersonToPFN;
