export const apiUrl = window.location.href.includes("localhost")
  ? "http://localhost:3055"
  : "https://api.qmngr.com";

export const statuses = {
  ticketed: "Not here",
  queued: "Queued",
  requested: "Requested",
  called: "Called",
  abandoned: "Abandoned" /* by agent */,
  expired: "Expired" /* by customer or system */,
  cancelled: "Cancelled" /* by customer */,
  started: "In Consultation",
  completed: "Completed",
  transferred: "Transferred" /*by agent*/,
  rated: "Rated" /*by customer*/,
};

export const chiaweloCatchmentArea = [
  [27.855822, -26.25836],
  [27.8365274, -26.2654115],
  [27.8373292, -26.2717803],
  [27.8359853, -26.2757632],
  [27.8434805, -26.2811003],
  [27.8508481, -26.2865604],
  [27.8516873, -26.2872312],
  [27.8517244, -26.2874483],
  [27.8514298, -26.2877478],
  [27.8517285, -26.2879667],
  [27.854825, -26.290292],
  [27.8563804, -26.2915197],
  [27.8573166, -26.2917106],
  [27.8583655, -26.2919192],
  [27.868352, -26.2898224],
  [27.8729679, -26.2879355],
  [27.8760386, -26.2848941],
  [27.8783002, -26.2828255],
  [27.8848213, -26.2798897],
  [27.8854496, -26.2795203],
  [27.8859555, -26.2791275],
  [27.8865422, -26.2783752],
  [27.8876126, -26.2722953],
  [27.8881452, -26.2708483],
  [27.8886159, -26.2702222],
  [27.8970986, -26.2613804],
  [27.883668, -26.2632245],
  [27.8818276, -26.2637702],
  [27.8807978, -26.2641382],
  [27.8798909, -26.2646162],
  [27.878852, -26.2652683],
  [27.8775453, -26.2663746],
  [27.875876, -26.2687412],
  [27.8637541, -26.2679243],
  [27.8632409, -26.2683917],
  [27.8627438, -26.2688676],
  [27.8602143, -26.2686536],
  [27.8591515, -26.2683176],
  [27.8582993, -26.2678353],
  [27.8576495, -26.2671771],
  [27.8573717, -26.266737],
  [27.8568407, -26.2653029],
  [27.8579203, -26.2649447],
  [27.855822, -26.25836],
];
export const countryNames = {
  BD: "Bangladesh",
  BE: "Belgium",
  BF: "Burkina Faso",
  BG: "Bulgaria",
  BA: "Bosnia and Herzegovina",
  BB: "Barbados",
  WF: "Wallis and Futuna",
  BL: "Saint Barthelemy",
  BM: "Bermuda",
  BN: "Brunei",
  BO: "Bolivia",
  BH: "Bahrain",
  BI: "Burundi",
  BJ: "Benin",
  BT: "Bhutan",
  JM: "Jamaica",
  BV: "Bouvet Island",
  BW: "Botswana",
  WS: "Samoa",
  BQ: "Bonaire, Saint Eustatius and Saba",
  BR: "Brazil",
  BS: "Bahamas",
  JE: "Jersey",
  BY: "Belarus",
  BZ: "Belize",
  RU: "Russia",
  RW: "Rwanda",
  RS: "Serbia",
  TL: "East Timor",
  RE: "Reunion",
  TM: "Turkmenistan",
  TJ: "Tajikistan",
  RO: "Romania",
  TK: "Tokelau",
  GW: "Guinea-Bissau",
  GU: "Guam",
  GT: "Guatemala",
  GS: "South Georgia and the South Sandwich Islands",
  GR: "Greece",
  GQ: "Equatorial Guinea",
  GP: "Guadeloupe",
  JP: "Japan",
  GY: "Guyana",
  GG: "Guernsey",
  GF: "French Guiana",
  GE: "Georgia",
  GD: "Grenada",
  GB: "United Kingdom",
  GA: "Gabon",
  SV: "El Salvador",
  GN: "Guinea",
  GM: "Gambia",
  GL: "Greenland",
  GI: "Gibraltar",
  GH: "Ghana",
  OM: "Oman",
  TN: "Tunisia",
  JO: "Jordan",
  HR: "Croatia",
  HT: "Haiti",
  HU: "Hungary",
  HK: "Hong Kong",
  HN: "Honduras",
  HM: "Heard Island and McDonald Islands",
  VE: "Venezuela",
  PR: "Puerto Rico",
  PS: "Palestinian Territory",
  PW: "Palau",
  PT: "Portugal",
  SJ: "Svalbard and Jan Mayen",
  PY: "Paraguay",
  IQ: "Iraq",
  PA: "Panama",
  PF: "French Polynesia",
  PG: "Papua New Guinea",
  PE: "Peru",
  PK: "Pakistan",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Poland",
  PM: "Saint Pierre and Miquelon",
  ZM: "Zambia",
  EH: "Western Sahara",
  EE: "Estonia",
  EG: "Egypt",
  ZA: "South Africa",
  EC: "Ecuador",
  IT: "Italy",
  VN: "Vietnam",
  SB: "Solomon Islands",
  ET: "Ethiopia",
  SO: "Somalia",
  ZW: "Zimbabwe",
  SA: "Saudi Arabia",
  ES: "Spain",
  ER: "Eritrea",
  ME: "Montenegro",
  MD: "Moldova",
  MG: "Madagascar",
  MF: "Saint Martin",
  MA: "Morocco",
  MC: "Monaco",
  UZ: "Uzbekistan",
  MM: "Myanmar",
  ML: "Mali",
  MO: "Macao",
  MN: "Mongolia",
  MH: "Marshall Islands",
  MK: "Macedonia",
  MU: "Mauritius",
  MT: "Malta",
  MW: "Malawi",
  MV: "Maldives",
  MQ: "Martinique",
  MP: "Northern Mariana Islands",
  MS: "Montserrat",
  MR: "Mauritania",
  IM: "Isle of Man",
  UG: "Uganda",
  TZ: "Tanzania",
  MY: "Malaysia",
  MX: "Mexico",
  IL: "Israel",
  FR: "France",
  IO: "British Indian Ocean Territory",
  SH: "Saint Helena",
  FI: "Finland",
  FJ: "Fiji",
  FK: "Falkland Islands",
  FM: "Micronesia",
  FO: "Faroe Islands",
  NI: "Nicaragua",
  NL: "Netherlands",
  NO: "Norway",
  NA: "Namibia",
  VU: "Vanuatu",
  NC: "New Caledonia",
  NE: "Niger",
  NF: "Norfolk Island",
  NG: "Nigeria",
  NZ: "New Zealand",
  NP: "Nepal",
  NR: "Nauru",
  NU: "Niue",
  CK: "Cook Islands",
  XK: "Kosovo",
  CI: "Ivory Coast",
  CH: "Switzerland",
  CO: "Colombia",
  CN: "China",
  CM: "Cameroon",
  CL: "Chile",
  CC: "Cocos Islands",
  CA: "Canada",
  CG: "Republic of the Congo",
  CF: "Central African Republic",
  CD: "Democratic Republic of the Congo",
  CZ: "Czech Republic",
  CY: "Cyprus",
  CX: "Christmas Island",
  CR: "Costa Rica",
  CW: "Curacao",
  CV: "Cape Verde",
  CU: "Cuba",
  SZ: "Swaziland",
  SY: "Syria",
  SX: "Sint Maarten",
  KG: "Kyrgyzstan",
  KE: "Kenya",
  SS: "South Sudan",
  SR: "Suriname",
  KI: "Kiribati",
  KH: "Cambodia",
  KN: "Saint Kitts and Nevis",
  KM: "Comoros",
  ST: "Sao Tome and Principe",
  SK: "Slovakia",
  KR: "South Korea",
  SI: "Slovenia",
  KP: "North Korea",
  KW: "Kuwait",
  SN: "Senegal",
  SM: "San Marino",
  SL: "Sierra Leone",
  SC: "Seychelles",
  KZ: "Kazakhstan",
  KY: "Cayman Islands",
  SG: "Singapore",
  SE: "Sweden",
  SD: "Sudan",
  DO: "Dominican Republic",
  DM: "Dominica",
  DJ: "Djibouti",
  DK: "Denmark",
  VG: "British Virgin Islands",
  DE: "Germany",
  YE: "Yemen",
  DZ: "Algeria",
  US: "United States",
  UY: "Uruguay",
  YT: "Mayotte",
  UM: "United States Minor Outlying Islands",
  LB: "Lebanon",
  LC: "Saint Lucia",
  LA: "Laos",
  TV: "Tuvalu",
  TW: "Taiwan",
  TT: "Trinidad and Tobago",
  TR: "Turkey",
  LK: "Sri Lanka",
  LI: "Liechtenstein",
  LV: "Latvia",
  TO: "Tonga",
  LT: "Lithuania",
  LU: "Luxembourg",
  LR: "Liberia",
  LS: "Lesotho",
  TH: "Thailand",
  TF: "French Southern Territories",
  TG: "Togo",
  TD: "Chad",
  TC: "Turks and Caicos Islands",
  LY: "Libya",
  VA: "Vatican",
  VC: "Saint Vincent and the Grenadines",
  AE: "United Arab Emirates",
  AD: "Andorra",
  AG: "Antigua and Barbuda",
  AF: "Afghanistan",
  AI: "Anguilla",
  VI: "U.S. Virgin Islands",
  IS: "Iceland",
  IR: "Iran",
  AM: "Armenia",
  AL: "Albania",
  AO: "Angola",
  AQ: "Antarctica",
  AS: "American Samoa",
  AR: "Argentina",
  AU: "Australia",
  AT: "Austria",
  AW: "Aruba",
  IN: "India",
  AX: "Aland Islands",
  AZ: "Azerbaijan",
  IE: "Ireland",
  ID: "Indonesia",
  UA: "Ukraine",
  QA: "Qatar",
  MZ: "Mozambique",
};
export const tldToCountryCode = {
  "+27": "ZA",
  "+1": "US",
  "+44": "GB",
  "+93": "AF",
  "+355": "AL",
  "+213": "DZ",
  "+1684": "AS",
  "+376": "AD",
  "+244": "AO",
  "+1264": "AI",
  "+1268": "AG",
  "+54": "AR",
  "+374": "AM",
  "+297": "AW",
  "+61": "AU",
  "+43": "AT",
  "+994": "AZ",
  "+1242": "BS",
  "+973": "BH",
  "+880": "BD",
  "+1246": "BB",
  "+375": "BY",
  "+32": "BE",
  "+501": "BZ",
  "+229": "BJ",
  "+1441": "BM",
  "+975": "BT",
  "+591": "BO",
  "+387": "BA",
  "+267": "BW",
  "+55": "BR",
  "+246": "IO",
  "+1284": "VG",
  "+673": "BN",
  "+359": "BG",
  "+226": "BF",
  "+257": "BI",
  "+855": "KH",
  "+237": "CM",
  "+238": "CV",
  "+599": "CW",
  "+1345": "KY",
  "+236": "CF",
  "+235": "TD",
  "+56": "CL",
  "+86": "CN",
  "+57": "CO",
  "+269": "KM",
  "+243": "CD",
  "+242": "CG",
  "+682": "CK",
  "+506": "CR",
  "+225": "CI",
  "+385": "HR",
  "+53": "CU",
  "+357": "CY",
  "+420": "CZ",
  "+45": "DK",
  "+253": "DJ",
  "+1767": "DM",
  "+593": "EC",
  "+20": "EG",
  "+503": "SV",
  "+240": "GQ",
  "+291": "ER",
  "+372": "EE",
  "+251": "ET",
  "+500": "FK",
  "+298": "FO",
  "+679": "FJ",
  "+358": "FI",
  "+33": "FR",
  "+594": "GF",
  "+689": "PF",
  "+241": "GA",
  "+220": "GM",
  "+995": "GE",
  "+49": "DE",
  "+233": "GH",
  "+350": "GI",
  "+30": "GR",
  "+299": "GL",
  "+1473": "GD",
  "+590": "MF",
  "+1671": "GU",
  "+502": "GT",
  "+224": "GN",
  "+245": "GW",
  "+592": "GY",
  "+509": "HT",
  "+504": "HN",
  "+852": "HK",
  "+36": "HU",
  "+354": "IS",
  "+91": "IN",
  "+62": "ID",
  "+98": "IR",
  "+964": "IQ",
  "+353": "IE",
  "+972": "IL",
  "+39": "VA",
  "+1876": "JM",
  "+81": "JP",
  "+962": "JO",
  "+7": "RU",
  "+254": "KE",
  "+686": "KI",
  "+965": "KW",
  "+996": "KG",
  "+856": "LA",
  "+371": "LV",
  "+961": "LB",
  "+266": "LS",
  "+231": "LR",
  "+218": "LY",
  "+423": "LI",
  "+370": "LT",
  "+352": "LU",
  "+853": "MO",
  "+389": "MK",
  "+261": "MG",
  "+265": "MW",
  "+60": "MY",
  "+960": "MV",
  "+223": "ML",
  "+356": "MT",
  "+692": "MH",
  "+596": "MQ",
  "+222": "MR",
  "+230": "MU",
  "+52": "MX",
  "+691": "FM",
  "+373": "MD",
  "+377": "MC",
  "+976": "MN",
  "+382": "ME",
  "+1664": "MS",
  "+212": "MA",
  "+258": "MZ",
  "+95": "MM",
  "+264": "NA",
  "+674": "NR",
  "+977": "NP",
  "+31": "NL",
  "+687": "NC",
  "+64": "NZ",
  "+505": "NI",
  "+227": "NE",
  "+234": "NG",
  "+683": "NU",
  "+672": "NF",
  "+850": "KP",
  "+1670": "MP",
  "+47": "NO",
  "+968": "OM",
  "+92": "PK",
  "+680": "PW",
  "+970": "PS",
  "+507": "PA",
  "+675": "PG",
  "+595": "PY",
  "+51": "PE",
  "+63": "PH",
  "+48": "PL",
  "+351": "PT",
  "+974": "QA",
  "+262": "RE",
  "+40": "RO",
  "+250": "RW",
  "+290": "SH",
  "+1869": "KN",
  "+1758": "LC",
  "+508": "PM",
  "+1784": "VC",
  "+685": "WS",
  "+378": "SM",
  "+239": "ST",
  "+966": "SA",
  "+221": "SN",
  "+381": "RS",
  "+248": "SC",
  "+232": "SL",
  "+65": "SG",
  "+1721": "SX",
  "+421": "SK",
  "+386": "SI",
  "+677": "SB",
  "+252": "SO",
  "+82": "KR",
  "+211": "SS",
  "+34": "ES",
  "+94": "LK",
  "+249": "SD",
  "+597": "SR",
  "+268": "SZ",
  "+46": "SE",
  "+41": "CH",
  "+963": "SY",
  "+886": "TW",
  "+992": "TJ",
  "+255": "TZ",
  "+66": "TH",
  "+670": "TL",
  "+228": "TG",
  "+690": "TK",
  "+676": "TO",
  "+1868": "TT",
  "+216": "TN",
  "+90": "TR",
  "+993": "TM",
  "+1649": "TC",
  "+688": "TV",
  "+1340": "VI",
  "+256": "UG",
  "+380": "UA",
  "+971": "AE",
  "+598": "UY",
  "+998": "UZ",
  "+678": "VU",
  "+58": "VE",
  "+84": "VN",
  "+681": "WF",
  "+967": "YE",
  "+260": "ZM",
  "+263": "ZW",
};
