import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

class ServicePointList extends Component {
  render() {
    const bookingsByService = {};
    this.props.bookings.forEach((booking) => {
      const day = moment(booking.estimated_call_time).format("DD-MM-YYYY");
      bookingsByService[day] = bookingsByService[day] || {};
      booking.services.forEach((x) => {
        bookingsByService[day][x.name] = bookingsByService[day][x.name] || 0;
        bookingsByService[day][x.name]++;
      });
    });

    return (
      <div className={"uk-padding days-container closed"}>
        <ul className={"uk-list day-list"}>
          {this.props.branch &&
            this.props.branch.services.map(
              (service) =>
                !service.is_hidden && (
                  <li
                    className={
                      this.props.request.required_services.includes(service._id)
                        ? "capitalize qm-selected"
                        : "capitalize"
                    }
                    key={service._id}
                    onClick={() => this.props.toggleService(service._id)}
                  >
                    <span>{service.name}</span>{" "}
                    {bookingsByService[this.props.request.preferred_date] &&
                      bookingsByService[this.props.request.preferred_date][
                        service.name
                      ] && (
                        <em className={"muted"}>
                          (
                          {
                            bookingsByService[
                              this.props.request.preferred_date
                            ][service.name]
                          }{" "}
                          bookings)
                        </em>
                      )}
                  </li>
                )
            )}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    request: state.request,
    services: state.services,
    branch: state.branch,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    toggleService: (serviceId) =>
      dispatch({ type: "TOGGLE_SERVICE", serviceId }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ServicePointList);
