import { graphRequest } from "../../helpers";

const setBranchAction = function (branchId, branch, sticky) {
  return { type: "SET_BRANCH", branchId, branch, sticky };
};

export const setBranchActionAsync = function (branchId, sticky = false) {
  return (dispatch) => {
    if (branchId) {
      graphRequest(
        `query {branch(branch_id: "${branchId}") {
          _id,
          name,
          addr {
          country {
          tld
          }
          }
          services {
          _id
          name
          is_hidden
          },
          settings {
          ticketing {
          advance_ticketing_gap
          }
          days_closed
          operating_hours
          {
          Sunday{opens
          closes
             }
          Monday{opens
          closes
             }
          Tuesday{opens
          closes
             }
          Wednesday{opens
          closes
             }
          Thursday{opens
          closes
             }
          Friday{opens
          closes
             }
          Saturday{opens
          closes
             }
          }
          }
    }
    }`,
        { Passthru: true },
        null
      )
        .then((response) => {
          const responseBody = response.data.branch;

          if (responseBody.code && responseBody.message !== "") {
            this.props.setError(
              responseBody.message || responseBody.code || responseBody.error
            );
          } else if (responseBody._id) {
            dispatch(setBranchAction(responseBody._id, responseBody, sticky));
          }
        })
        .catch((err) => console.log(err));
    }
  };
};
