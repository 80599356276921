import React, { useState } from "react";
import Swal from "sweetalert2";
import { apiUrl } from "../constants/index";
import { Redirect } from "react-router-dom";
import { post } from "../helpers";

const Login = function () {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasToken, setHasToken] = useState(false);

  const login = () => {
    if (username && password) {
      post(`${apiUrl}/chiawelo/login`, {
        username,
        password,
      })
        .then((resp) => resp.json())
        .then((json) => {
          if (json.success) {
            localStorage.setItem("_chiawelo_token", json.token);
            setHasToken(true);
          } else {
            Swal.fire({
              text: json.message,
              icon: "warning",
              position: "top",
            });
          }
        })
        .catch(console.log);
    }
  };

  if (hasToken) {
    return <Redirect to="/desk" />;
  }

  return (
    <div className={"uk-padding-large"}>
      <div className={"uk-text-center uk-align-center uk-padding-large"}>
        <div>
          <img
            width="250"
            alt="joinaq"
            src={"https://assets.joinaq.com/assets/logo.svg"}
          />
          <h1>Sign In</h1>
        </div>
        <div
          style={{
            margin: "auto",
            width: "50%",
            minWidth: "300px",
            maxWidth: "100%",
          }}
        >
          <div className={"uk-row"}>
            <input autoComplete="new-password"
              className={"uk-input uk-text-center"}
              placeholder={"Username"}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </div>
          <div className={"uk-row"}>
            <input autoComplete="new-password"
              className={"uk-input uk-text-center"}
              type={"password"}
              placeholder={"Password"}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </div>
          <div className={"uk-row"}>
            <button className={"uk-button uk-button-danger"} onClick={login}>
              Sign In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
