import React, { Fragment, useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactPlayer from "react-player";
import Swal from "sweetalert2";
import { graphRequest } from "../helpers";
import Main from "./Main";
import AddPFNForm from "./AddPFNForm";
import Ticket from "./Ticket";
import webSocketsHandler from "../helpers/webSocketsHandler";
import { apiUrl } from "../constants";
import moment from "moment";
//import {ReactComponent as WhatsappIcon} from "../assets/whatsapp-icon.svg";
const apiHost = apiUrl.replace(/https?:\/\//i, "");
const soundFile = "https://assets.joinaq.com/sounds/quite-impressed-565.mp3";
const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const slug = "5f0c30b14a36c700156efac3";
const branchId = "5f0c4f0f4a36c700156efaf2";
const tld = "+27";
const Toast = Swal.mixin({
  toast: true,
  position: "top-right",
  iconColor: "white",
  showConfirmButton: false,
  timer: 5000,
  timerProgressBar: false,
});
// await Toast.fire({
//   icon: "success",
//   title: "Success",
// });
// await Toast.fire({
//   icon: "error",
//   title: "Error",
// });
// await Toast.fire({
//   icon: "warning",
//   title: "Warning",
// });

// await Toast.fire({
//   icon: "question",
//   title: "Question",
// });

const CloseIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      fill="none"
      stroke="#000"
      stroke-width="1.1"
      x1="1"
      y1="1"
      x2="13"
      y2="13"
    ></line>
    <line
      fill="none"
      stroke="#000"
      stroke-width="1.1"
      x1="13"
      y1="1"
      x2="1"
      y2="13"
    ></line>
  </svg>
);

const notificationsReducer = (notifications, action) => {
  let updatedNotifications = {};
  switch (action.type) {
    case "remove":
      updatedNotifications = {
        ...notifications,
      };
      delete updatedNotifications[action.ticketId];
      break;
    case "add":
      updatedNotifications = {
        ...notifications,
        [action.notification.id]: action.notification,
      };
      break;

    default:
      updatedNotifications = { ...notifications };
  }
  return updatedNotifications;
};

const Ui = ({
  setPosition,
  setBranches,
  setBrand,
  announceNewTicket,
  ticketSucceeded,
  showSignIn,
  showFrame,
  setError,
}) => {
  const [notifications, setNotifications] = useReducer(
    notificationsReducer,
    {}
  );
  const [online, setOnline] = useState(navigator.onLine);
  const [callUrl, setCallUrl] = useState("");

  useEffect(() => {
   // no longer able to connect until upgraded to match joinaq session token system enableSocketListener(branchId);
    getBranches();

    const handleOnline = () => {
      setOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOnline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOnline);
    };
  }, []);

  const playNotification = () => {
    try {
      webAudioTouchUnlock(audioContext)
        .then(() => {
          new Audio(soundFile).play();
        })
        .catch(function (e) {
          console.log({ e });
        });
    } catch (e) {
      console.log({ e });
    }
  };

  const webAudioTouchUnlock = (context) => {
    return new Promise(function (resolve, reject) {
      if (context.state === "suspended" && "ontouchstart" in window) {
        const unlock = function () {
          context.resume().then(
            function () {
              document.body.removeEventListener("touchstart", unlock);
              document.body.removeEventListener("touchend", unlock);

              resolve(true);
            },
            function (reason) {
              reject(reason);
            }
          );
        };

        document.body.addEventListener("message", unlock, false);
        document.body.addEventListener("touchstart", unlock, false);
        document.body.addEventListener("touchend", unlock, false);
      } else {
        resolve(false);
      }
    });
  };

  const enableSocketListener = (channel) => {
    webSocketsHandler(apiHost, "/announce/socket.io", [`tickets.${channel}`], {
      "new-ticket": async ({ ticket }) => {
        if (ticket.issuance_method !== "concierge") {
          announceNewTicket();
          await Toast.fire({
            icon: "info",
            title: "New WhatsApp Booking received!",
            text: moment(ticket.estimated_call_time).format("LLL"),
          });
        }
      },
      "changed-ticket": (data) => {
        const { ticket, callout } = data;
        if (ticket && ticket.status === "called") {
          if (ticket.station) {
            playNotification();
            markAsCalled(ticket, callout, 60000);
          }
        }
      },
    });
  };

  const getBranches = () => {
    if (slug) {
      graphRequest(
        `query {branches(brand_id: "${slug}"${
          branchId ? `, branch_id: "${branchId}"` : ""
        }) {
          _id
          name
          brand {
          background
          primary_text_color
          }
          branches {
          name
          _id
          status
          }
    }
    }`,
        { Passthru: true },
        {}
      )
        .then(({ data }) => {
          if (data.branches) {
            const responseBody = data.branches;
            if (responseBody.code && responseBody.message !== "") {
              const { message, error, code } = responseBody;
              setError(message || code || error);
            } else {
              const { branches, brand, logo, name } = responseBody;
              setBranches(branches);
              setBrand(
                Object.assign(brand, {
                  logo,
                  name,
                })
              );
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const markAsCalled = (ticket, calloutUri, hideTimeout = 60000) => {
    const notification = {
      id: ticket._id,
      type: "primary",
      time: moment.tz("Africa/Johannesburg"),
      message: `${
        ticket.alias || ticket.number
      } has been called to ${ticket.station.type.toUpperCase()} ${
        ticket.station.name
      }. Please let him/her know.`,
    };
    setNotifications({ notification, type: "add" });
    const callUrl =
      calloutUri ||
      `https://speech.joinaq.com/audio/${ticket._id}.mp3`;
    setCallUrl(callUrl);
    setTimeout(() => {
      setCallUrl("");
    }, 12000);

    const timeout = setTimeout(() => {
      clearCalled(ticket._id);
      clearTimeout(timeout);
    }, hideTimeout);
  };

  const clearCalled = (ticketId) => {
    setNotifications({ ticketId, type: "remove" });
  };

  const refresh = () => window.location.reload();

  return (
    <Fragment>
      {callUrl && (
        <ReactPlayer url={callUrl} playing onEnded={() => null} volume={10} />
      )}
      {!online && (
        <div className="uk-alert uk-alert-danger uk-text-center" data-uk-alert>
          You have lost your internet connection. Please check the connection
          then{" "}
          <a href=" #" onClick={refresh}>
            refresh the page
          </a>
          .
        </div>
      )}
      {!!notifications &&
        Object.keys(notifications).map((callId) => (
          <div
            className={`uk-alert uk-text-center uk-alert-${
              notifications[callId].type || "primary"
            }`}
            data-uk-alert
          >
            <a
              href={" #"}
              className="uk-alert-close uk-icon uk-close"
              onClick={() => clearCalled(callId)}
              data-uk-close
            >
              <CloseIcon />
            </a>
            <strong>
              {moment(notifications[callId].time).format("h:mmA")}
            </strong>{" "}
            - {notifications[callId].message}
          </div>
        ))}
      {showFrame && <Main branchId={branchId} />}
      {showSignIn && <AddPFNForm tld={tld} />}
      {ticketSucceeded && <Ticket />}
    </Fragment>
  );
};

const mapStateToProps = function ({
  showSignIn,
  showFrame,
  ticketSucceeded,
  position,
}) {
  return {
    showSignIn,
    showFrame,
    ticketSucceeded,
    position,
  };
};

const mapDispatchToProps = function (dispatch) {
  return {
    setPosition: (position) => dispatch({ type: "SET_POSITION", position }),
    setBranches: (branches) => dispatch({ type: "SET_BRANCHES", branches }),
    setBrand: (brand) => dispatch({ type: "SET_BRAND", brand }),
    announceNewTicket: () => dispatch({ type: "NEW_TICKET_ALERT" }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Ui));
