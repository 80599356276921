/* eslint-disable no-unreachable */
import { convertCountryCodeToDiallingCode } from "../../helpers";

const defaultState = {
  error: null,
  showFrame: true,
  branches: [],
  branch: null,
  brand: { background: "#bf263c", primary_text_color: "#fff" },
  services: [],
  called: null,
  isNoob: false,
  request: {
    required_services: ["606cc9536047c60015cdc440" /*vitals*/],
    tld: "+27",
    facility_id: null,
  },
  signUpEnabled: false,
  ticketSucceeded: false,
  isSticky: false,
  inStore: false,
};

const rootReducer = function (state = defaultState, action) {
  let newState = { ...state, error: null };

  switch (action.type) {
    case "SET_TIMEBIAS":
      newState = {
        ...newState,
        request: {
          ...newState.request,
          bias:
            newState.request.bias === action.bias ? null : action.bias,
          required_services: [],
        },
      };
      break;

    case "SET_BRANCHES":
      newState = { ...newState, branches: action.branches };
      break;
    case "SET_BRANCH":
      const facilityId =
        newState.request.facility_id === action.branchId
          ? null
          : action.branchId;

      if (action.sticky && action.branch._id) {
        newState.request.facility_id = action.branch._id;
        newState.request.tld = convertCountryCodeToDiallingCode(
          action.branch.addr.country.tld
        );
      }

      newState = {
        ...newState,
        branch: action.branch,
        request: {
          ...newState.request,
          facility_id: facilityId,
          preferred_date: null,
          bias: null,
          required_services: [],
        },
        isSticky: action.sticky,
        inStore: action.inStore,
      };

      break;

    case "SET_BRAND":
      newState = { ...newState, brand: action.brand };
      break;

    case "SET_DATE":
      newState = {
        ...newState,
        request: {
          ...newState.request,
          preferred_date:
            newState.request.preferred_date === action.preferredDate
              ? null
              : action.preferredDate,
          bias: null,
          required_services: [],
        },
      };
      break;

    case "TOGGLE_SERVICE":
      const toggleService = (state, item) => {
        let basket = [...state.request.required_services];
        if (basket.includes(item)) {
          basket = basket.filter((basketItem) => basketItem !== item);
        } else {
          basket = [...basket, item];
        }

        return {
          request: {
            ...state.request,
            required_services: [
              ...new Set([...basket, "606cc9536047c60015cdc440"]),
            ],
          },
        };
      };
      newState = { ...newState, ...toggleService(newState, action.serviceId) };
      break;

    case "JOIN":
      const { facility_id, required_services, bias, preferred_date } =
        newState.request;
      if (!facility_id) {
        const error = { message: "Please pick a location" };
        return { ...newState, error };
      }
      if (!preferred_date) {
        const error = { message: "When do you need service?" };
        return { ...newState, error };
      }
      if (!bias) {
        const error = { message: "You need to choose a time" };
        return { ...newState, error };
      }
      if (Array.isArray(required_services) && required_services.length < 1) {
        const error = { message: "Please pick at least 1 service" };
        return { ...newState, error };
      }

      newState = {
        ...newState,
        showSignIn: true,
        showFrame: false,
        signInEnabled: false,
        ticketSucceeded: false,
      };
      break;

    case "SET_ERROR":
      const error = { message: action.error };
      return { ...newState, error };
      break;

    case "SET_POSITION":
      newState = { ...newState, position: action.position };
      break;

    case "TOGGLE_SIGNUP":
      newState = {
        ...newState,
        signUpEnabled: !newState.signUpEnabled,
        showFrame: false,
        showSignIn: true,
        signInEnabled: false,
        ticketSucceeded: false,
      };
      break;
    case "SHOW_FRAME":
      newState = {
        ...newState,
        showFrame: true,
        showSignIn: false,
        signInEnabled: false,
        ticketSucceeded: false,
      };
      break;

    case "SET_TICKET_TYPE":
      newState = { ...newState, isNoob: action.isNoob };
      break;

    case "SET_SUCCESS":
      newState = {
        ...newState,
        ticket: action.ticket,
        ticketSucceeded: true,
        signUpEnabled: false,
        showSignIn: false,
        showFrame: false,
      };
      break;

    case "":
    case "CLEAR_SESSION":
      newState = { ...defaultState };
      break;

    case "NEW_TICKET_ALERT":
      newState = { ...newState, receivedNewTicket: true };
      break;

    case "CLEAR_TICKET_ALERT":
      newState = { ...newState, receivedNewTicket: false };
      break;

    case "CLEAR_REQUEST":
      const emptyRequest = { ...defaultState.request };
      newState = { ...newState, request: emptyRequest };
      break;

    default:
      action.type = "";
  }

  return newState;
};

export default rootReducer;
