import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class BrandLogo extends Component {
  render() {
    return (
      <Fragment>
        {this.props.brand.logo && (
          <div
            className="logo"
            style={{
              background: this.props.brand.background,
              color: this.props.brand.primary_text_color,
              fontFamily: "Roboto !important",
            }}
          >
            <div>
              <img
                src={this.props.brand.logo}
                height="100"
                width="100"
                alt={this.props.brand.name}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    brand: state.brand,
  };
};

export default connect(mapStateToProps)(BrandLogo);
