import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";

const NumberCorrectionInput = ({
  oldNumber,
  updateNumber,
  setIsMobileValid = () => null,
}) => {
  const cleanedOldNumber = oldNumber.replace(/\s+/g, "");
  const [correctNumber, setCorrectNumber] = useState("");
  const [, setSaving] = useState(false);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    if (
      oldNumber &&
      cleanedOldNumber.match(/^(0[56789]|[+]?27[1-9])[1-9][0-9]{7}/)
    ) {
      setIsValid(true);
      setIsMobileValid(true);
    } else {
      setIsValid(false);
      setIsMobileValid(false);
    }
  }, []);

  const handleUpdate = async () => {
    setSaving(true);
    updateNumber(oldNumber, correctNumber)
      .then((result) => {
        setSaving(false);
        if (result.updated) {
          setIsMobileValid(result.updated);
        } else {
          Swal.fire({
            title: "Number not saved",
            text:
              result.message ||
              "Please use a valid phone number without spaces, brackets or dashes",
            icon: "danger",
          });
        }
      })
      .catch((e) => {
        setSaving(false);
      });
  };

  if (isValid) {
    return null;
  }

  return (
    <>
      <dt>Please correct their phone number</dt>
      <dd>
        <span className={"grid-2"}>
          <input autoComplete="new-password"
            className="uk-input"
            placeholder={"Enter mobile number e.g 0761234567"}
            onKeyUp={(evt) => setCorrectNumber(evt.target.value)}
            onBlur={handleUpdate}
          />
        </span>
      </dd>
    </>
  );
};

export default NumberCorrectionInput;
