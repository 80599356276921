import moment from "moment-timezone";
import axios from "axios";
import DeviceInfo from "device-detector-js";
import { tldToCountryCode, apiUrl } from "../constants";
moment.tz.setDefault("Africa/Johannesburg");

export const clock = function (time) {
  return time < 12
    ? `${time}AM`
    : time === 12
    ? `${time} Noon`
    : `${time - 12}PM`;
};

export const get = async (url) => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("_chiawelo_token");
  return fetch(url, {
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
      username,
    },
    redirect: "follow",
  });
};

export const put = async (url, body) => {
  return post(url, body, "put");
};

export const post = async (url, body, method = "post") => {
  const username = localStorage.getItem("username");
  const token = localStorage.getItem("_chiawelo_token");

  return fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      "x-token": token,
      username,
    },
    redirect: "follow",
    body: JSON.stringify(body),
  });
};

export const detectErrors = (payload) => {
  const errors = [];
  if (!payload) errors.push("Bad Data");

  if (!payload.facility_id) {
    errors.push("Missing branch information");
  }
  if (!payload.preferred_date) {
    errors.push("Please select a date");
  }
  if (!payload.bias) {
    errors.push("Please select a time");
  }
  if (
    !payload.required_services ||
    !Array.isArray(payload.required_services) ||
    (Array.isArray(payload.required_services) &&
      payload.required_services.length < 1)
  ) {
    errors.push("Please select at least 1 service");
  }
  if (!payload.customer_id) {
    errors.push("Please refresh the page.");
  }

  return errors;
};

export const formatDateTime = (timestamp, displayFormat, timezone) => {
  return moment(timestamp).tz(timezone).format(displayFormat);
};

export const isValidMobileNumber = (number) => {
  if(!number) return false;
  const numberString = number.toString();
  const regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  return regex.test(numberString);
};

export const isValidEmail = (email) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );

export const convertCountryCodeToDiallingCode = (tld) =>
  (Object.entries(tldToCountryCode).find(
    (pair) => "" + tld.toLocaleUpperCase() === pair[1]
  ) || [])[0];

/**
 * Calculates the seconds since midnight for a given time formatted as HH:mm.
 * @param {string} timeStr - The time string formatted as HH:mm.
 * @returns {number} The number of seconds since midnight.
 */
export const getSecondsSinceMidnight = (timeStr) => {
  const time = moment(timeStr, 'HH:mm');
  const hours = time.hours();
  const minutes = time.minutes();

  const secondsFromHours = hours * 3600;
  const secondsFromMinutes = minutes * 60;

  return secondsFromHours + secondsFromMinutes;
};

export const calculateActiveHours = (opensSeconds, closesSeconds) => {
  const opens = opensSeconds / 3600;
  const closes = closesSeconds / 3600;
  if (!(0 <= opens && opens <= 23) || isNaN(opens)) {
    throw Error(`Bad value passed for opens: ${opens}`);
  }
  if (!(0 <= closes && closes <= 23) || isNaN(closes)) {
    throw Error(`Bad value passed for closes: ${closes}`);
  }
  let opened = false;
  let currentHour = opens;
  let activeHours = [];
  let hoursCounted = 0;
  while (hoursCounted <= 24) {
    if (currentHour === opens) {
      opened = true;
    }
    if (currentHour === closes && hoursCounted > 0) {
      opened = false;
    }

    if (opened) {
      activeHours.push(currentHour);
    }

    activeHours = [...new Set(activeHours)];

    currentHour++;
    currentHour = currentHour > 23 ? 0 : currentHour;
    hoursCounted++;
  }
  return activeHours;
};

export const getUserLanguage = () => {
  const language = window.navigator.userLanguage || window.navigator.language;
  let translator = "en";
  if (language) {
    translator = language.split("-")[0];
  }
  return translator;
};

export const graphRequest = async function (
  query,
  headers,
  position,
  variables
) {
  const fingerprint = btoa(JSON.stringify(new DeviceInfo()));
  const { data } = await axios({
    method: "post",
    url: `${apiUrl}/graph/chiawelo`,
    data: variables ? { query, variables } : query,
    async: true,
    crossDomain: true,
    headers: {
      ...{
        fingerprint,
        "Content-Type": "application/graphql",
        "cache-control": "no-cache",
        Position: JSON.stringify(position),
      },
      ...headers,
    },
  });
  return data;
};
